import { cubicBezier } from '@styles/vars/cubicBezier.style'
import React from 'react'

const ToggleCross = ({
  width = 25,
  height = 25,
  fill = 'currentColor',
  responsive = true,
  open = true,
}) => {
  const svgAttributes = responsive
    ? {}
    : {
        width,
        height,
      }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 25 25"
      aria-hidden="true"
      focusable="false"
      {...svgAttributes}
    >
      <path
        fill={fill}
        d="M13.75 1.001a1 1 0 1 0-2 0h2Zm-2 22.5a1 1 0 1 0 2 0h-2Zm0-22.5v22.5h2v-22.5h-2Z"
        style={{
          transform: `scaleY(${open ? 0 : 1})`,
          transformOrigin: '50% 50%',
          transition: `transform 0.15s ${cubicBezier.inOut}`,
        }}
      />
      <path
        fill={fill}
        d="M24 13.251a1 1 0 1 0 0-2v2Zm-22.5-2a1 1 0 1 0 0 2v-2Zm22.5 0H1.5v2H24v-2Z"
      />
    </svg>
  )
}

export default ToggleCross
