import styled from 'styled-components'
import { clamp } from '@styles/utils/conversion.style'
import { RoundedImageWrapper } from '@components/RoundedImage/index.style'
import { AnimateImageMain } from '@components/animation/AnimateImage/index.style'
import { mq } from '@styles/vars/media-queries.style'

export const HeroWrapper = styled.div`
  ${clamp('padding-top', 106, 240)};
  position: relative;
  text-align: center;

  ${AnimateImageMain} > div,
  ${RoundedImageWrapper} > div {
    ${mq.tabletL} {
      aspect-ratio: 304 / 90;
    }
  }
`
