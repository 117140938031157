import React from 'react'
import PropTypes from 'prop-types'
import Container from '@components/Container'
import { TermsWrapper } from './index.style'
import { Accordion } from '@components/Accordion'
import RichText from '@components/RichText'

const Terms = ({ content }) => {
  return (
    <TermsWrapper>
      <Container>
        <Accordion
          items={[
            {
              id: 'terms-and-conditions-1',
              open: false,
              summary: 'Terms & Conditions',
              details: <RichText content={content} />,
            },
          ]}
        />
      </Container>
    </TermsWrapper>
  )
}

Terms.propTypes = {
  content: PropTypes.object,
}

export default Terms
