import React from 'react'

const IconArrowLeft = ({
  width = 15,
  height = 12,
  stroke = 'currentColor',
  responsive = true,
}) => {
  const svgAttributes = responsive
    ? {}
    : {
        width,
        height,
      }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 15 12"
      aria-hidden="true"
      focusable="false"
      {...svgAttributes}
    >
      <path
        d="M6 11 1 6m0 0 5-5M1 6h12.172"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default IconArrowLeft
