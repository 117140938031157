import React from 'react'
import PropTypes from 'prop-types'
import Container from '@components/Container'
import { HeroWrapper } from './index.style'
import { Super1 } from '@components/TextStyles'
import Spacer from '@components/Spacer'
import { GatsbyImage } from 'gatsby-plugin-image'
import RoundedImage from '@components/RoundedImage'
import AnimateSplitText from '@components/animation/AnimateSplitText'
import AnimateImage from '@components/animation/AnimateImage'

const Hero = ({ title, image }) => {
  return (
    <HeroWrapper>
      <Container>
        <Super1>
          <AnimateSplitText>{title}</AnimateSplitText>
        </Super1>
        <Spacer size={[24, 42]} />
        <RoundedImage>
          <AnimateImage hover={false}>
            <GatsbyImage image={image.gatsbyImageData} alt={image.description} />
          </AnimateImage>
        </RoundedImage>
      </Container>
    </HeroWrapper>
  )
}

Hero.propTypes = {
  title: PropTypes.string,
  image: PropTypes.object,
}

export default Hero
