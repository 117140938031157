import React, { useState, useEffect, useRef } from 'react'
import gsap from 'gsap'
import debounce from 'lodash/debounce'
import PropTypes from 'prop-types'
import {
  AccordionContent,
  AccordionContentInner,
  AccordionItemMain,
  AccordionToggle,
  AccordionToggleIcon,
} from './index.style'
import { useStore } from '@Store/'
import ToggleCross from '@svgs/ToggleCross'
import ScrollTrigger from 'gsap/ScrollTrigger'
import { TextBody } from '@components/TextStyles'

gsap.registerPlugin(ScrollTrigger)

const Accordion = ({ items, showOneItem = false }) => {
  const mountState = items.filter(item => item.open).map(item => item.id)
  const [openElements, setOpenElements] = useState(mountState)

  const toggleAccordion = id => {
    const newElements = openElements.includes(id)
      ? openElements.filter(value => value !== id)
      : showOneItem
      ? [id]
      : [id, ...openElements]

    setOpenElements(newElements)
  }

  return (
    <>
      {React.Children.toArray(
        items.map(item => (
          <AccordionItem
            summary={item.summary}
            open={openElements.includes(item.id)}
            handleToggle={() => toggleAccordion(item.id)}
            styled={true}
          >
            {item.details}
          </AccordionItem>
        ))
      )}
    </>
  )
}

Accordion.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  showOneItem: PropTypes.bool,
}

const AccordionItem = ({
  summary,
  children,
  handleToggle,
  styled,
  open = false,
}) => {
  const [store] = useStore()
  const $content = useRef()
  const $contentInner = useRef()
  const tl = useRef()

  useEffect(() => {
    const updateContentHeight = debounce(() => {
      if (!$content.current) return

      const contentHeight = parseInt($content.current.style.height, 10)

      if (contentHeight > 0) {
        gsap.set($content.current, {
          height: $contentInner.current.scrollHeight,
        })
      }
    }, 200)

    tl.current = gsap.timeline({
      paused: true,
      defaults: {
        duration: 0.3,
        ease: 'power2.inOut',
      },
      onComplete: () => {
        ScrollTrigger.refresh()
      },
    })

    window.addEventListener('resize', updateContentHeight)

    return () => {
      tl.current.kill()
      window.removeEventListener('resize', updateContentHeight)
    }
  }, [])

  useEffect(() => {
    if (!store.fontIsLoaded) return

    tl.current
      .to($content.current, {
        height: open ? $contentInner.current.scrollHeight : 0,
      })
      .to(
        $contentInner.current,
        {
          opacity: open ? 1 : 0,
        },
        open ? '<0.2' : '<'
      )

    tl.current.play()
  }, [store.fontIsLoaded, open])

  return (
    <AccordionItemMain styled={styled}>
      {summary && handleToggle && (
        <AccordionToggle onClick={handleToggle}>
          <TextBody>{summary}</TextBody>
          <AccordionToggleIcon>
            <ToggleCross open={open} />
          </AccordionToggleIcon>
        </AccordionToggle>
      )}

      <AccordionContent ref={$content}>
        <AccordionContentInner
          ref={$contentInner}
          aria-hidden={!open}
          styled={styled}
        >
          {children}
        </AccordionContentInner>
      </AccordionContent>
    </AccordionItemMain>
  )
}

AccordionItem.propTypes = {
  summary: PropTypes.string,
  children: PropTypes.node.isRequired,
  handleToggle: PropTypes.func,
  styled: PropTypes.bool,
  open: PropTypes.bool,
}

export { Accordion, AccordionItem }
