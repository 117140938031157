import styled from 'styled-components'
import { clamp } from '@styles/utils/conversion.style'
import { Label } from '@styles/vars/textStyles.style'
import { mq } from '@styles/vars/media-queries.style'
import { breakpoints } from '@styles/vars/breakpoints.style'

export const ContentWrapper = styled.div`
  padding-bottom: 2.8rem;
  ${clamp('padding-top', 48, 112)};

  ${mq.tabletL} {
    ${clamp(
      'padding-bottom',
      80,
      200,
      breakpoints.tabletL,
      breakpoints.contained
    )};
  }
`

export const ContentInner = styled.div`
  padding-bottom: 7.2rem;

  ${mq.tabletL} {
    padding-bottom: 0;
  }
`

export const ContentList = styled.ul`
  list-style-type: none;
  padding-top: 2.4rem;
`

export const ContentLists = styled.div`
  padding-bottom: 7.2rem;
  padding-top: 3.6rem;
  position: relative;

  ${mq.tabletL} {
    height: 100%;
    padding-bottom: 0;
    ${clamp(
      'padding-left',
      30,
      60,
      breakpoints.tabletL,
      breakpoints.contained
    )};
    padding-top: 0;
  }

  &:before {
    background-color: currentColor;
    content: '';
    height: 0.1rem;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;

    ${mq.tabletL} {
      bottom: 0;
      height: auto;
      right: auto;
      width: 0.1rem;
    }
  }

  ${ContentList} + ${Label} {
    padding-top: 5.6rem;
  }
`

export const ContentListItem = styled.li`
  padding-bottom: 1.8rem;
`
