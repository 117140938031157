import styled from 'styled-components'
import { clamp } from '@styles/utils/conversion.style'
import { colors } from '@styles/vars/colors.style'

export const AccordionItemMain = styled.div`
  ${props =>
    props.styled &&
    `
    background-color: #f7f7f7;
    border-radius: 2.4rem;
  `}
`

export const AccordionToggle = styled.button`
  position: relative;
  display: block;
  width: 100%;
  ${clamp('padding-top', 24, 40)}
  ${clamp('padding-left', 20, 40)}
  ${clamp('padding-bottom', 24, 40)}
  text-align: left;
  color: currentColor;
  border: none;
  background-color: transparent;
`

export const AccordionToggleIcon = styled.div`
  background-color: ${colors.light};
  border-radius: 50%;
  box-sizing: content-box;
  color: ${colors.blue};
  ${clamp('padding', 11, 15)};
  position: absolute;
  top: 50%;
  ${clamp('right', 20, 24)}
  transform: translateY(-50%);
  ${clamp('width', 14, 25)}
`

export const AccordionContent = styled.div`
  overflow: hidden;
  will-change: height;
`

export const AccordionContentInner = styled.div`
  ${props =>
    props.styled &&
    `
  ${clamp('padding-bottom', 60, 90)}
  ${clamp('padding-top', 24, 40)}
  ${clamp('padding-left', 20, 40)}
  ${clamp('padding-right', 20, 40)}
  `}
`
