import React from 'react'
import PropTypes from 'prop-types'
import Container from '@components/Container'
import {
  ContentWrapper,
  ContentInner,
  ContentLists,
  ContentList,
  ContentListItem,
} from './index.style'
import { Label } from '@components/TextStyles'
import Spacer from '@components/Spacer'
import Grid from '@components/Grid'
import GridItem from '@components/GridItem'
import RichText from '@components/RichText'
import Button from '@components/Button'
import IconArrowUpRight from '@svgs/IconArrowUpRight'
import IconArrowRight from '@svgs/IconArrowRight'
import IconArrowLeft from '@svgs/IconArrowLeft'
import SocialFacebook from '@svgs/SocialFacebook'
import SocialTwitter from '@svgs/SocialTwitter'
import AnimateFadeIn from '@components/animation/AnimateFadeIn'
import { getSocialShareUrl } from '@utils'

const Content = ({ content, title, sidebarLists, showSocial, backLink }) => {
  return (
    <ContentWrapper>
      <Container>
        <Grid>
          <GridItem tabletL={7} desk={5} deskStart={3}>
            <ContentInner>
              <RichText content={content} />
              {backLink && (
                <>
                  <Spacer size={65} />
                  <AnimateFadeIn>
                    <Button
                      type="internalLink"
                      to={backLink.slug}
                      iconLeft={<IconArrowLeft />}
                    >
                      Back to {backLink.title}
                    </Button>
                  </AnimateFadeIn>
                </>
              )}
            </ContentInner>
          </GridItem>
          {(sidebarLists || showSocial) && (
            <GridItem tabletL={4} tabletLStart={9} desk={3} deskStart={9}>
              <ContentLists>
                {sidebarLists &&
                  React.Children.toArray(
                    sidebarLists.map(list => (
                      <>
                        <Label as="div">
                          <AnimateFadeIn>{list.title}</AnimateFadeIn>
                        </Label>
                        <ContentList>
                          {React.Children.toArray(
                            list.links.map(link => {
                              const type = link.page
                                ? 'internalLink'
                                : 'externalLink'

                              return (
                                <ContentListItem>
                                  <AnimateFadeIn>
                                    <Button
                                      type={type}
                                      href={
                                        link.url
                                          ? link.url
                                          : link.resource?.file?.url
                                      }
                                      toPage={link.page}
                                      iconRight={
                                        type === 'internalLink' ? (
                                          <IconArrowRight />
                                        ) : (
                                          <IconArrowUpRight />
                                        )
                                      }
                                      iconOrientation={
                                        type === 'internalLink' ? 'horizontal'  : 'vertical'
                                      }
                                    >
                                      {link.text}
                                    </Button>
                                  </AnimateFadeIn>
                                </ContentListItem>
                              )
                            })
                          )}
                        </ContentList>
                      </>
                    ))
                  )}
                {showSocial && (
                  <>
                    <Label as="div">
                      <AnimateFadeIn>Share on Social Media</AnimateFadeIn>
                    </Label>
                    <ContentList>
                      <ContentListItem>
                        <AnimateFadeIn>
                          <Button
                            type={'button'}
                            onClick={() => getSocialShareUrl('facebook')}
                            iconLeft={<SocialFacebook />}
                            iconOrientation="vertical"
                          >
                            Facebook
                          </Button>
                        </AnimateFadeIn>
                      </ContentListItem>
                      <ContentListItem>
                        <AnimateFadeIn>
                          <Button
                            type={'button'}
                            onClick={() => getSocialShareUrl('twitter', title)}
                            iconLeft={<SocialTwitter />}
                            iconOrientation="vertical"
                          >
                            Twitter
                          </Button>
                        </AnimateFadeIn>
                      </ContentListItem>
                    </ContentList>
                  </>
                )}
              </ContentLists>
            </GridItem>
          )}
        </Grid>
      </Container>
    </ContentWrapper>
  )
}

Content.propTypes = {
  content: PropTypes.object,
  title: PropTypes.string,
  sidebarLists: PropTypes.array,
  showSocial: PropTypes.bool,
  backLink: PropTypes.object,
}

export default Content
